import React from "react";
import moment from "moment";

const JiraLatestComment = ({ data }) => {
  const comments = data?.fields?.comment?.comments;

  if (!comments || comments.length === 0) {
    return <div>No comments available</div>;
  }

  const latestComments = comments.slice(-3).reverse();

  const renderContent = (content) => {
    return content.map((block, index) => {
      if (block.type === "paragraph") {
        return block.content.map((text, idx) => (
          <span key={`${index}-${idx}`}>{text.text} </span>
        ));
      } else if (block.type === "orderedList" || block.type === "bulletList") {
        const isOrdered = block.type === "orderedList";
        return (
          <ul
            key={index}
            style={{
              listStyleType: isOrdered ? "decimal" : "disc",
              marginLeft: "20px",
            }}
          >
            {block.content.map((listItem, idx) => (
              <li key={`${index}-${idx}`}>
                {listItem.content.map((paragraph, pIdx) =>
                  paragraph.content.map((text, tIdx) => (
                    <span key={`${index}-${idx}-${pIdx}-${tIdx}`}>
                      {text.text}{" "}
                    </span>
                  ))
                )}
              </li>
            ))}
          </ul>
        );
      }
      return null;
    });
  };

  return (
    <div className="comments-container">
      {latestComments.map((comment, index) => {
        const authorName = comment?.author?.displayName;
        const commentBody = renderContent(comment?.body?.content);
        const date = comment?.created;

        return (
          <div key={index} className="comment-card">
            <div className="comment-header">
              <span className="comment-author">{authorName}</span>
              <span className="comment-date">
                {moment(date).format("DD MMMM YYYY")}
              </span>
            </div>
            <div className="comment-body">{commentBody}</div>
          </div>
        );
      })}
    </div>
  );
};

export default JiraLatestComment;
