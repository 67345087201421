import React from "react";
import styled from "styled-components";

const ProgressBarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    margin-left: 10px;
    font-size: 14px;
  }
`;

const Label = styled.span`
  width: 130px;
  font-size: 14px;
  font-weight: bold;
  color: #6b7280;
`;

const ProgressBar = styled.div`
  flex: 1;
  height: 12px;
  background-color: #e5e7eb; /* Light gray background */
  border-radius: 8px;
  margin-left: 10px;
  overflow: hidden;
`;

const Progress = styled.div`
  height: 100%;
  border-radius: 8px;
  transition: width 0.4s ease;
  opacity: 0.8;
`;

const ProgressBars = ({ data }) => {
  return (
    <ProgressBarsContainer>
      <ProgressBarContainer>
        <Label>{data.label}</Label>
        <ProgressBar>
          <Progress
            style={{
              width: `${data.percent}%`,
              backgroundColor: data.color,
            }}
          />
        </ProgressBar>
        <p>{data.percent}%</p>
      </ProgressBarContainer>
    </ProgressBarsContainer>
  );
};

export default ProgressBars;
