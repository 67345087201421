import React from "react";
import Seo from "../../../components/seo";
import PMOProjectDetail from "../../../components/ProjectDetail/pmo";
import queryString from "query-string";

const PMOProjectDetailPage = (props) => {
  const { search } = props.location;
  const { id } = queryString.parse(search);
  return (
    <>
      <Seo title="ProjectDetail" />
      <PMOProjectDetail {...props} id={id} />
    </>
  );
};

export default PMOProjectDetailPage;
